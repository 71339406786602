const menu = document.querySelector(".header__links");
const headerlogo = document.querySelector(".header__logo");
// get mobile animated open/close element
const newMenuBtn = document.querySelector('.cAnimatedExpander');

/* add Event Listener to mobile animated open/close element */
newMenuBtn.addEventListener("click", toggleMenu, false);

const items = document.querySelectorAll(".item");

/* Trigger a Button Click on Enter */
// Get the input field
var input = document.getElementById("search");
// Execute a function when the user presses a key on the keyboard
input.addEventListener("keypress", function(event) {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === "Enter") {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      document.getElementById("myBtn").click();
    }
  }); 

function toggleHeight() {
    var elem = this.querySelector(".submenu");
    // If the element is visible, hide it
    if (elem.classList.contains('is-visible')) {
        hide(elem);
        return;
    }
    // Otherwise, show it
    show(elem);
};

// .submenu passed as arg
function show(elem) {
    // Get the natural height of the element
    var getHeight = function() {
        elem.style.display = 'block'; // Make it visible
        var height = elem.scrollHeight + 'px'; // Get it's height
        elem.style.display = ''; //  Hide it again
        return height;
    };

    var height = getHeight(); // Get the natural height
    elem.classList.add('is-visible'); // Make the element visible
    elem.style.height = height; // Update the max-height

    // Once the transition is complete, remove the inline max-height so the content can scale responsively
    window.setTimeout(function() {
        elem.style.height = '';
    }, 350);
    // add down class to submenu
    elem.parentElement.classList.add('down');
};

// Hide an element
function hide(elem) {
    // Give the element a height to change from
    elem.style.height = elem.scrollHeight + 'px';

    // Set the height back to 0
    window.setTimeout(function() {
        elem.style.height = '0';
    }, 1);

    // When the transition is complete, hide it
    window.setTimeout(function() {
        elem.classList.remove('is-visible');
    }, 350);
    elem.parentElement.classList.remove('down');

};

/* Toggle mobile menu */
function toggleMenu() {

    const socials = document.querySelector(".header__socials");
    // look for hidden class which appended in mobile view
    if (menu.classList.contains("hidden")) {
        // first remove hidden class that has display none
        // before asyncronously remove visullayhidden class that has opacity 0
        menu.classList.remove('hidden');
        // then add cAnimatedExpander close class that has css trans animation 
        newMenuBtn.classList.add('close');
        setTimeout(function() {
            menu.classList.remove('visuallyhidden');
        }, 20);
        headerlogo.classList.add("activelogo");
        socials.style.display = "none"; 
    } else {
        // else the mobile menu is not present
        // first add visuallhidden class that has opacity 0 property
        // then asyncronously add hidden bolck display class
        menu.classList.add('visuallyhidden');
        newMenuBtn.classList.remove('close');
        menu.addEventListener('transitionend', function(e) {
            menu.classList.add('hidden');
            headerlogo.classList.remove("activelogo");
            socials.style.display = "flex";
        }, {
            capture: false,
            once: true,
            passive: false
        });        
    }
}

export function runNav() {
    /* Event Listeners */
    for (let item of items) {
        if (item.querySelector(".submenu")) {
            // add event listener when the mobile submenu is clicked
            item.addEventListener("click", toggleHeight, false);
            //item.addEventListener("keypress", toggleSubMenuItem, false);
        }
        newMenuBtn.addEventListener("click", toggleMenu, false);
    }
}